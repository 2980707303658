const joggingsListItems = {
  joggings: [
    {id: 1,   cityId: 1,  date: 1506891600, distance: 2260,  time:1079, slides: 0 , isCompetition: false , parts: [473,483]},
    {id: 2,   cityId: 1,  date: 1507064400, distance: 5250,  time:2467, slides: 0 , isCompetition: false , parts: [434,455,466,498,490]},
    {id: 3,   cityId: 1,  date: 1507237200, distance: 5230,  time:2377, slides: 0 , isCompetition: false , parts: [418,449,461,478,467]},
    {id: 4,   cityId: 1,  date: 1507496400, distance: 5230,  time:2509, slides: 0 , isCompetition: false , parts: [426,475,493,508,488]},
    {id: 5,   cityId: 1,  date: 1507669200, distance: 5250,  time:2119, slides: 0 , isCompetition: false , parts: [411,403,401,404,400]},
    {id: 6,   cityId: 1,  date: 1508706000, distance: 5160,  time:1994, slides: 0 , isCompetition: false , parts: [389,377,386,392,386]},
    {id: 7,   cityId: 1,  date: 1508878800, distance: 5110,  time:2059, slides: 0 , isCompetition: false , parts: [368,395,397,419,437]},
    {id: 8,   cityId: 1,  date: 1509051600, distance: 5120,  time:1965, slides: 0 , isCompetition: false , parts: [349,366,392,398,407]},
    {id: 9,   cityId: 1,  date: 1509919200, distance: 5200,  time:2009, slides: 0 , isCompetition: false , parts: [368,376,384,395,403]},
    {id: 10,  cityId: 1,  date: 1510092000, distance: 5170,  time:1947, slides: 0 , isCompetition: false , parts: [341,370,375,394,396]},
    {id: 11,  cityId: 1,  date: 1510264800, distance: 5250,  time:1867, slides: 0 , isCompetition: false , parts: [324,341,351,373,378]},
    {id: 12,  cityId: 1,  date: 1510524000, distance: 5220,  time:1852, slides: 0 , isCompetition: false , parts: [318,318,353,362,406]},
    {id: 13,  cityId: 1,  date: 1510696800, distance: 5230,  time:1843, slides: 0 , isCompetition: false , parts: [311,352,370,360,372]},
    {id: 14,  cityId: 1,  date: 1510869600, distance: 5220,  time:1873, slides: 0 , isCompetition: false , parts: [320,336,357,378,394]},
    {id: 15,  cityId: 1,  date: 1511042400, distance: 2740,  time:883,  slides: 7 , isCompetition: "https://vseprobegi.org/race/469"  , parts: [304,325]},
    {id: 16,  cityId: 1,  date: 1511128800, distance: 5210,  time:1785, slides: 0 , isCompetition: false , parts: [321,335,354,348,346]},
    {id: 17,  cityId: 1,  date: 1511301600, distance: 5360,  time:1803, slides: 0 , isCompetition: false , parts: [318,291,324,356,367]},
    {id: 18,  cityId: 1,  date: 1511474400, distance: 5370,  time:1800, slides: 0 , isCompetition: false , parts: [290,316,343,365,365]},
    {id: 19,  cityId: 1,  date: 1511733600, distance: 5220,  time:2078, slides: 0 , isCompetition: false , parts: [337,405,424,406,408]},
    {id: 20,  cityId: 1,  date: 1511906400, distance: 5060,  time:1989, slides: 0 , isCompetition: false , parts: [360,393,401,407,403]},
    {id: 21,  cityId: 1,  date: 1512079200, distance: 5350,  time:1975, slides: 0 , isCompetition: false , parts: [305,367,381,393,386]},
    {id: 22,  cityId: 1,  date: 1512338400, distance: 5110,  time:1900, slides: 0 , isCompetition: false , parts: [360,357,375,376,393]},
    {id: 23,  cityId: 1,  date: 1512684000, distance: 5310,  time:2090, slides: 0 , isCompetition: false , parts: [343,382,407,412,412]},
    {id: 24,  cityId: 1,  date: 1512943200, distance: 5570,  time:2170, slides: 0 , isCompetition: false , parts: [382,391,399,385,382]},
    {id: 25,  cityId: 1,  date: 1513116000, distance: 5560,  time:2132, slides: 0 , isCompetition: false , parts: [355,375,388,392,395]},
    {id: 26,  cityId: 1,  date: 1515535200, distance: 6420,  time:2364, slides: 0 , isCompetition: false , parts: [348,365,374,368,371,371]},
    {id: 27,  cityId: 1,  date: 1515967200, distance: 6170,  time:2214, slides: 0 , isCompetition: false , parts: [337,342,343,359,381,380]},
    {id: 28,  cityId: 1,  date: 1522616400, distance: 5050,  time:2151, slides: 0 , isCompetition: false , parts: [331,426,434,459,475]},
    {id: 29,  cityId: 1,  date: 1522789200, distance: 5040,  time:2055, slides: 0 , isCompetition: false , parts: [351,385,419,422,460]},
    {id: 30,  cityId: 1,  date: 1523221200, distance: 5070,  time:2051, slides: 0 , isCompetition: false , parts: [355,382,421,411,451]},
    {id: 31,  cityId: 1,  date: 1523826000, distance: 5050,  time:2060, slides: 0 , isCompetition: false , parts: [361,395,442,412,428]},
    {id: 32,  cityId: 1,  date: 1523998800, distance: 5040,  time:1991, slides: 0 , isCompetition: false , parts: [363,375,376,405,451]},
    {id: 33,  cityId: 1,  date: 1524344400, distance: 5020,  time:1758, slides: 7 , isCompetition: "http://kyivhalfmarathon.org"  , parts: [310,346,359,341,387]},
    {id: 34,  cityId: 1,  date: 1524603600, distance: 5020,  time:2324, slides: 0 , isCompetition: false , parts: [397,459,505,464,485]},
    {id: 35,  cityId: 1,  date: 1526418000, distance: 5000,  time:2399, slides: 0 , isCompetition: false , parts: [2399]},
    {id: 36,  cityId: 1,  date: 1526590800, distance: 5000,  time:2260, slides: 0 , isCompetition: false , parts: [2260]},
    {id: 37,  cityId: 1,  date: 1526850000, distance: 5030,  time:2514, slides: 0 , isCompetition: false , parts: [417,523,493,503,565]},
    {id: 38,  cityId: 1,  date: 1527109200, distance: 5000,  time:2378, slides: 0 , isCompetition: false , parts: [2378]},
    {id: 39,  cityId: 1,  date: 1527195600, distance: 5160,  time:2370, slides: 0 , isCompetition: false , parts: [424,478,435,463,480]},
    {id: 40,  cityId: 1,  date: 1528664400, distance: 5000,  time:2174, slides: 0 , isCompetition: false , parts: [400,418,460,433,459]},
    {id: 41,  cityId: 1,  date: 1528750800, distance: 7370,  time:3023, slides: 0 , isCompetition: false , parts: [322,416,430,410,435,418,430]},
    {id: 42,  cityId: 1,  date: 1528837200, distance: 5000,  time:2161, slides: 0 , isCompetition: false , parts: [438,427,435,426,435]},
    {id: 43,  cityId: 1,  date: 1530392400, distance: 8010,  time:3120, slides: 5 , isCompetition: "https://athletic-events.com/events/445" , parts: [348,374,378,391,387,411,411,406]},
    {id: 44,  cityId: 1,  date: 1531515600, distance: 6190,  time:2626, slides: 0 , isCompetition: false , parts: [2626]},
    {id: 45,  cityId: 1,  date: 1531688400, distance: 6190,  time:2215, slides: 0 , isCompetition: false , parts: [345,338,353,352,372,385]},
    {id: 46,  cityId: 1,  date: 1531861200, distance: 5170,  time:1852, slides: 0 , isCompetition: false , parts: [311,333,361,378,403]},
    {id: 47,  cityId: 1,  date: 1536008400, distance: 5190,  time:1873, slides: 0 , isCompetition: false , parts: [313,354,368,364,394]},
    {id: 48,  cityId: 1,  date: 1536440400, distance: 5220,  time:1797, slides: 5 , isCompetition: "https://intersportrun.org.ua/rezultati-kiyiv/"  , parts:[342,337,358,340,317]},
    {id: 49,  cityId: 1,  date: 1536526800, distance: 10070, time:3873, slides: 0 , isCompetition: false , parts: [331,374,384,392,402,394,393,395,383,393]},
    {id: 50,  cityId: 1,  date: 1536786000, distance: 10070, time:4018, slides: 0 , isCompetition: false , parts: [341,372,388,379,409,398,411,437,413,435]},
    {id: 51,  cityId: 1,  date: 1537131600, distance: 5070,  time:1893, slides: 0 , isCompetition: false , parts: [332,367,367,375,418]},
    {id: 52,  cityId: 1,  date: 1537390800, distance: 10080, time:3781, slides: 0 , isCompetition: false , parts: [338,355,371,359,374,371,374,399,395,412]},
    {id: 53,  cityId: 1,  date: 1537736400, distance: 5030,  time:1831, slides: 0 , isCompetition: false , parts: [343,351,365,378,376]},
    {id: 54,  cityId: 2,  date: 1538254800, distance: 10790, time:3857, slides: 6 , isCompetition: "https://marathon.md/ru/2018results"  , parts: [317,320,359,375,345,342,349,373,368,393]},
    {id: 55,  cityId: 1,  date: 1538859600, distance: 21770, time:9274, slides: 8 , isCompetition: "https://kyivmarathon.org/"  , parts: [362,352,373,357,359,350,349,409,388,420,417,463,431,490,548,454,444,479,449,521,452]},
    {id: 56,  cityId: 1,  date: 1539723600, distance: 5020,  time:2092, slides: 0 , isCompetition: false , parts: [340,397,425,440,478]},
    {id: 57,  cityId: 1,  date: 1539896400, distance: 5040,  time:2050, slides: 0 , isCompetition: false , parts: [345,381,422,429,449]},
    {id: 58,  cityId: 1,  date: 1540155600, distance: 5040,  time:2060, slides: 0 , isCompetition: false , parts: [354,400,416,422,445]},
    {id: 59,  cityId: 1,  date: 1540328400, distance: 5050,  time:1989, slides: 0 , isCompetition: false , parts: [362,382,407,390,423]},
    {id: 60,  cityId: 1,  date: 1540764000, distance: 5040,  time:2150, slides: 0 , isCompetition: false , parts: [374,409,428,450,464]},
    {id: 61,  cityId: 1,  date: 1540936800, distance: 5100,  time:2049, slides: 0 , isCompetition: false , parts: [357,373,410,423,444]},
    {id: 62,  cityId: 1,  date: 1541109600, distance: 5070,  time:1933, slides: 0 , isCompetition: false , parts: [347,377,395,371,412]},
    {id: 63,  cityId: 1,  date: 1541196000, distance: 5130,  time:1801, slides: 0 , isCompetition: false , parts: [327,347,345,367,381]},
    {id: 64,  cityId: 1,  date: 1541368800, distance: 5160,  time:1777, slides: 0 , isCompetition: false , parts: [301,317,345,369,379]},
    {id: 65,  cityId: 1,  date: 1541541600, distance: 5050,  time:2055, slides: 0 , isCompetition: false , parts: [314,374,413,450,476]},
    {id: 66,  cityId: 1,  date: 1541973600, distance: 5480,  time:1982, slides: 0 , isCompetition: false , parts: [330,351,360,370,382]},
    {id: 67,  cityId: 3,  date: 1542405600, distance: 10050, time:3801, slides: 9 , isCompetition: "https://sportevent.com.ua/events/sumy-autumn2018/" , parts: [329,345,363,369,375,388,398,415,402,394]},
    {id: 68,  cityId: 1,  date: 1542578400, distance: 6030,  time:2211, slides: 0 , isCompetition: false , parts: [323,341,363,375,407,389]},
    {id: 69,  cityId: 1,  date: 1542751200, distance: 3030,  time:1213, slides: 0 , isCompetition: false , parts: [365,407,427]},
    {id: 70,  cityId: 1,  date: 1543356000, distance: 6070,  time:2566, slides: 0 , isCompetition: false , parts: [428,420,415,425,409,439]},
    {id: 71,  cityId: 1,  date: 1543701600, distance: 10040, time:4448, slides: 0 , isCompetition: false , parts: [425,395,449,427,463,400,499,419,487,470]},
    {id: 72,  cityId: 1,  date: 1544306400, distance: 10040, time:3831, slides: 0 , isCompetition: false , parts: [329,362,352,358,373,389,411,412,424,404]},
    {id: 73,  cityId: 4,  date: 1545516000, distance: 5320,  time:1876, slides: 8 , isCompetition: "http://www.newrun.com.ua/rez/winter_rez.html" , parts: [334,314,353,368,378]},
    {id: 74,  cityId: 1,  date: 1546207200, distance: 2040,  time:835,  slides: 0 , isCompetition: false , parts: [383,423]},
    {id: 75,  cityId: 1,  date: 1546380000, distance: 6040,  time:2410, slides: 0 , isCompetition: false , parts: [395,393,407,399,407,394]},
    {id: 76,  cityId: 1,  date: 1546466400, distance: 4110,  time:1783, slides: 0 , isCompetition: false , parts: [414,410,438,467]},
    {id: 77,  cityId: 1,  date: 1546984800, distance: 6610,  time:2533, slides: 0 , isCompetition: false , parts: [364,459,360,370,375,372]},
    {id: 78,  cityId: 1,  date: 1547244000, distance: 10120, time:4051, slides: 0 , isCompetition: false , parts: [343,387,385,408,413,409,408,419,415,419]},
    {id: 79,  cityId: 1,  date: 1547676000, distance: 2290,  time:869,  slides: 0 , isCompetition: false , parts: [350,405]},
    {id: 80,  cityId: 1,  date: 1547935200, distance: 4300,  time:1712, slides: 0 , isCompetition: false , parts: [381,387,415,414]},
    {id: 81,  cityId: 1,  date: 1548108000, distance: 4030,  time:1341, slides: 0 , isCompetition: false , parts: [309,338,337,346]},
    {id: 82,  cityId: 1,  date: 1548626400, distance: 2090,  time:852,  slides: 0 , isCompetition: false , parts: [403,414]},
    {id: 83,  cityId: 1,  date: 1549749600, distance: 3100,  time:1021, slides: 8 , isCompetition: "https://www.toprunners.org/relay" , parts: [307,346,337]},
    {id: 84,  cityId: 1,  date: 1550354400, distance: 7030,  time:2993, slides: 0 , isCompetition: false , parts: [426,391,408,433,431,429,459]},
    {id: 85,  cityId: 1,  date: 1550527200, distance: 10040, time:4143, slides: 0 , isCompetition: false , parts: [340,421,410,426,416,392,441,405,455,416]},
    {id: 86,  cityId: 1,  date: 1550786400, distance: 3110,  time:1073, slides: 0 , isCompetition: false , parts: [279,355,380]},
    {id: 87,  cityId: 1,  date: 1551045600, distance: 7050,  time:2868, slides: 0 , isCompetition: false , parts: [379,384,392,399,427,419,441]},
    {id: 88,  cityId: 1,  date: 1551218400, distance: 10080, time:4123, slides: 0 , isCompetition: false , parts: [352,408,395,403,419,410,425,422,422,431]},
    {id: 89,  cityId: 1,  date: 1551564000, distance: 14070, time:5797, slides: 0 , isCompetition: false , parts: [325,391,417,401,424,402,426,421,424,426,424,429,419,432]},
    {id: 90,  cityId: 1,  date: 1552773600, distance: 7050,  time:2671, slides: 0 , isCompetition: false , parts: [358,351,378,385,392,383,402]},
    {id: 91,  cityId: 1,  date: 1553032800, distance: 10110, time:4239, slides: 0 , isCompetition: false , parts: [384,416,407,418,427,422,437,424,441,414]},
    {id: 92,  cityId: 1,  date: 1553292000, distance: 14060, time:5723, slides: 0 , isCompetition: false , parts: [299,393,392,397,398,396,411,408,412,414,415,434,431,437]},
    {id: 93,  cityId: 1,  date: 1553551200, distance: 8160,  time:3411, slides: 0 , isCompetition: false , parts: [378,409,417,415,437,421,439,427]},
    {id: 94,  cityId: 1,  date: 1554584400, distance: 21550, time:9195, slides: 6 , isCompetition: "https://kyivhalfmarathon.org" , parts: [334,318,361,362,388,370,383,377,377,412,407,437,460,431,434,480,472,517,540,510,542]},
    {id: 95,  cityId: 1,  date: 1560891600, distance: 5030,  time:2234, slides: 0 , isCompetition: false , parts: [412,423,449,451,483]},
    {id: 96,  cityId: 1,  date: 1561150800, distance: 5040,  time:2313, slides: 0 , isCompetition: false , parts: [429,453,465,460,485]},
    {id: 97,  cityId: 1,  date: 1561323600, distance: 5020,  time:2292, slides: 0 , isCompetition: false , parts: [413,423,482,472,488]},
    {id: 98,  cityId: 1,  date: 1561496400, distance: 5020,  time:2278, slides: 0 , isCompetition: false , parts: [405,430,456,450,478]},
    {id: 99,  cityId: 1,  date: 1561669200, distance: 5010,  time:2209, slides: 0 , isCompetition: false , parts: [420,420,448,445,462]},
    {id: 100, cityId: 1,  date: 1561842000, distance: 5070,  time:2283, slides: 0 , isCompetition: false , parts: [442,408,461,456,457]},
    {id: 101, cityId: 1,  date: 1562014800, distance: 5020,  time:2203, slides: 0 , isCompetition: false , parts: [415,425,450,455,445]},
    {id: 102, cityId: 1,  date: 1562187600, distance: 5030,  time:2097, slides: 0 , isCompetition: false , parts: [385,395,420,450,440]},
    {id: 103, cityId: 1,  date: 1562446800, distance: 5020,  time:2335, slides: 0 , isCompetition: false , parts: [445,430,490,480,480]},
    {id: 104, cityId: 1,  date: 1563051600, distance: 5170,  time:2298, slides: 0 , isCompetition: false , parts: [390,442,463,448,475]},
    {id: 105, cityId: 1,  date: 1563224400, distance: 5060,  time:2131, slides: 0 , isCompetition: false , parts: [406,414,410,422,446]},
    {id: 106, cityId: 1,  date: 1563656400, distance: 10140, time:4579, slides: 0 , isCompetition: false , parts: [437,436,457,454,472,461,465,479,447,465]},
    {id: 107, cityId: 1,  date: 1563829200, distance: 8000,  time:3510, slides: 0 , isCompetition: false , parts: [384,420,434,447,460,452,454,454]},
    {id: 108, cityId: 1,  date: 1564002000, distance: 5010,  time:2230, slides: 0 , isCompetition: false , parts: [402,434,454,459,472]},
    {id: 109, cityId: 1,  date: 1564261200, distance: 5050,  time:2233, slides: 0 , isCompetition: false , parts: [442,437,455,436,441]},
    {id: 110, cityId: 1,  date: 1564520400, distance: 10090, time:4253, slides: 0 , isCompetition: false , parts: [369,364,422,416,428,438,436,446,439,453]},
    {id: 111, cityId: 1,  date: 1565038800, distance: 5040,  time:2148, slides: 0 , isCompetition: false , parts: [411,432,433,425,423]},
    {id: 112, cityId: 1,  date: 1565470800, distance: 10030, time:4511, slides: 0 , isCompetition: false , parts: [402,433,461,454,457,446,448,470,457,467]},
    {id: 113, cityId: 1,  date: 1567382400, distance: 3030,  time:1372, slides: 0 , isCompetition: false , parts: [459,462,435]},
    {id: 114, cityId: 1,  date: 1567468800, distance: 5100,  time:2186, slides: 0 , isCompetition: false , parts: [418,399,447,435,441]},
    {id: 115, cityId: 1,  date: 1567728000, distance: 5030,  time:2213, slides: 0 , isCompetition: false , parts: [411,436,444,454,452]},
    {id: 116, cityId: 1,  date: 1567814400, distance: 5040,  time:2344, slides: 0 , isCompetition: false , parts: [435,469,473,466,480]},
    {id: 117, cityId: 1,  date: 1567555200, distance: 5110,  time:2221, slides: 0 , isCompetition: false , parts: [390,443,440,448,450]},
    {id: 118, cityId: 1,  date: 1567987200, distance: 10060, time:4572, slides: 0 , isCompetition: false , parts: [424,452,444,460,453,443,463,462,472,468]},
    {id: 119, cityId: 1,  date: 1568073600, distance: 3050,  time:1380, slides: 0 , isCompetition: false , parts: [449,454,454]},
    {id: 120, cityId: 1,  date: 1568332800, distance: 3160,  time:1322, slides: 0 , isCompetition: false , parts: [402,429,421]},
    {id: 121, cityId: 1,  date: 1568419200, distance: 5030,  time:2036, slides: 0 , isCompetition: false , parts: [391,416,411,409,395]},
    {id: 122, cityId: 1,  date: 1568505600, distance: 5050,  time:2014, slides: 0 , isCompetition: false , parts: [371,408,407,402,404]},
    {id: 123, cityId: 1,  date: 1568592000, distance: 3000,  time:1218, slides: 0 , isCompetition: false , parts: [402,408,408]},
    {id: 124, cityId: 1,  date: 1568678400, distance: 5120,  time:1971, slides: 0 , isCompetition: false , parts: [383,371,391,381,398]},
    {id: 125, cityId: 1,  date: 1568851200, distance: 10330, time:4347, slides: 0 , isCompetition: false , parts: [373,395,416,399,424,432,426,448,434,449]},
    {id: 126, cityId: 1,  date: 1568937600, distance: 5140,  time:2120, slides: 0 , isCompetition: false , parts: [373,404,429,421,424]},
    {id: 127, cityId: 1,  date: 1569024000, distance: 5090,  time:2044, slides: 0 , isCompetition: false , parts: [374,403,411,397,417]},
    {id: 128, cityId: 1,  date: 1569196800, distance: 5180,  time:2214, slides: 0 , isCompetition: false , parts: [411,432,428,418,446]},
    {id: 129, cityId: 1,  date: 1569283200, distance: 5150,  time:2164, slides: 0 , isCompetition: false , parts: [397,424,425,431,423]},
    {id: 130, cityId: 1,  date: 1569369600, distance: 5160,  time:1994, slides: 0 , isCompetition: false , parts: [394,379,389,382,387]},
    {id: 131, cityId: 1,  date: 1569456000, distance: 7100,  time:2933, slides: 0 , isCompetition: false , parts: [400,406,422,407,417,421,420]},
    {id: 132, cityId: 1,  date: 1569628800, distance: 5040,  time:2037, slides: 0 , isCompetition: false , parts: [395,416,413,392,402]},
    {id: 133, cityId: 1,  date: 1569888000, distance: 6220,  time:2721, slides: 0 , isCompetition: false , parts: [378,430,439,454,455,464]},
    {id: 134, cityId: 1,  date: 1570320000, distance: 21660, time:9568, slides: 1 , isCompetition: "https://kyivmarathon.org/" , parts: [365,374,379,420,429,433,454,422,383,425,446,443,451,455,392,457,461,496,577,505,483]},
    {id: 135, cityId: 1,  date: 1575939600, distance: 6150,  time:2175, slides: 0 , isCompetition: false , parts: [2175]},
    {id: 136, cityId: 1,  date: 1578445200, distance: 5060,  time:2281, slides: 0 , isCompetition: false , parts: [404,443,473,458,472]},
    {id: 137, cityId: 1,  date: 1578531600, distance: 5200,  time:2290, slides: 0 , isCompetition: false , parts: [425,447,442,444,441]},
    {id: 138, cityId: 1,  date: 1578618000, distance: 5200,  time:2237, slides: 0 , isCompetition: false , parts: [402,427,428,446,445]},
    {id: 139, cityId: 1,  date: 1578877200, distance: 7140,  time:3111, slides: 0 , isCompetition: false , parts: [369,420,435,431,455,464,469]},
    {id: 140, cityId: 1,  date: 1579050000, distance: 5110,  time:2220, slides: 0 , isCompetition: false , parts: [413,421,438,436,460]},
    {id: 141, cityId: 1,  date: 1579222800, distance: 5090,  time:2301, slides: 0 , isCompetition: false , parts: [402,419,469,468,497]},
    {id: 142, cityId: 1,  date: 1579568400, distance: 10230, time:4756, slides: 0 , isCompetition: false , parts: [423,444,465,455,444,482,470,495,474,471]},
    {id: 143, cityId: 1,  date: 1579741200, distance: 7150,  time:3116, slides: 0 , isCompetition: false , parts: [406,425,435,422,445,456,456]},
    {id: 144, cityId: 1,  date: 1579914000, distance: 7220,  time:3149, slides: 0 , isCompetition: false , parts: [342,426,450,441,467,456,464]},
    {id: 145, cityId: 1,  date: 1580173200, distance: 8060,  time:3735, slides: 0 , isCompetition: false , parts: [419,444,448,475,468,491,473,486]}, 
    {id: 146, cityId: 1,  date: 1580346000, distance: 8050,  time:3579, slides: 0 , isCompetition: false , parts: [402,441,437,450,450,464,457,458]},
    {id: 147, cityId: 1,  date: 1580518800, distance: 10060, time:4317, slides: 0 , isCompetition: false , parts: [407,441,439,419,445,420,414,420,433,448]},
    {id: 148, cityId: 1,  date: 1580778000, distance: 8170,  time:3798, slides: 0 , isCompetition: false , parts: [436,474,449,475,467,495,466,465]},
    {id: 149, cityId: 1,  date: 1580950800, distance: 8080,  time:3863, slides: 0 , isCompetition: false , parts: [439,482,485,478,484,481,489,489]},
    {id: 150, cityId: 1,  date: 1581123600, distance: 8080,  time:3455, slides: 0 , isCompetition: false , parts: [393,412,433,422,440,434,436,451]},
    {id: 151, cityId: 1,  date: 1581382800, distance: 8050,  time:3895, slides: 0 , isCompetition: false , parts: [469,499,492,506,492,489,468,457]},
    {id: 152, cityId: 1,  date: 1581555600, distance: 8090,  time:3787, slides: 0 , isCompetition: false , parts: [437,477,458,474,475,483,466,471]},
    {id: 153, cityId: 1,  date: 1581728400, distance: 10110, time:4679, slides: 0 , isCompetition: false , parts: [419,455,467,452,469,477,485,483,454,470]},
    {id: 154, cityId: 1,  date: 1581987600, distance: 8280,  time:3840, slides: 0 , isCompetition: false , parts: [428,461,466,461,467,471,485,469]},
    {id: 155, cityId: 1,  date: 1582333200, distance: 8100,  time:3635, slides: 0 , isCompetition: false , parts: [413,434,454,442,468,468,437,469]},
    {id: 156, cityId: 1,  date: 1582592400, distance: 8270,  time:3803, slides: 0 , isCompetition: false , parts: [462,463,469,456,467,465,453,449]},
    {id: 157, cityId: 1,  date: 1582765200, distance: 8320,  time:3738, slides: 0 , isCompetition: false , parts: [445,441,454,443,455,443,466,441]},
    {id: 158, cityId: 1,  date: 1582938000, distance: 8260,  time:3603, slides: 0 , isCompetition: false , parts: [407,431,441,421,445,453,445,441]},
	{id: 159, cityId: 1,  date: 1583197200, distance: 8170,  time:3805, slides: 0 , isCompetition: false , parts: [446,472,468,460,466,469,478,468]},
	{id: 160, cityId: 1,  date: 1583370000, distance: 8390,  time:3954, slides: 0 , isCompetition: false , parts: [417,453,470,468,483,480,503,486]},
	{id: 161, cityId: 1,  date: 1585443600, distance: 5150,  time:2225, slides: 0 , isCompetition: false , parts: [378,430,437,439,462]},
	{id: 162, cityId: 1,  date: 1585872000, distance: 5110,  time:2269, slides: 0 , isCompetition: false , parts: [418,455,442,442,460]},
	{id: 163, cityId: 5,  date: 1587600000, distance: 5140,  time:2584, slides: 0 , isCompetition: false , parts: [486,529,492,512,496]},
	{id: 164, cityId: 5,  date: 1587772800, distance: 5060,  time:2596, slides: 0 , isCompetition: false , parts: [480,552,491,517,526]},
	{id: 165, cityId: 5,  date: 1588032000, distance: 5490,  time:2906, slides: 0 , isCompetition: false , parts: [506,552,525,537,544]},
	{id: 166, cityId: 5,  date: 1588204800, distance: 5670,  time:2876, slides: 0 , isCompetition: false , parts: [533,511,539,503,476]},
	{id: 167, cityId: 5,  date: 1588377600, distance: 8230,  time:4053, slides: 0 , isCompetition: false , parts: [521,536,500,484,477,468,516,452]},
    {id: 168, cityId: 5,  date: 1588636800, distance: 8270,  time:4142, slides: 0 , isCompetition: false , parts: [529,535,498,479,475,492,531,479]},
    {id: 169, cityId: 5,  date: 1588809600, distance: 8150,  time:4011, slides: 0 , isCompetition: false , parts: [509,518,493,476,468,484,529,465]},
    {id: 170, cityId: 5,  date: 1588982400, distance: 11080, time:5559, slides: 0 , isCompetition: false , parts: [508,551,510,483,481,492,546,516,495,480,457]},
	{id: 171, cityId: 5,  date: 1589155200, distance: 8100,  time:4232, slides: 0 , isCompetition: false , parts: [564,587,521,486,526,529,498,477]},
	{id: 172, cityId: 5,  date: 1589328000, distance: 8240,  time:4031, slides: 0 , isCompetition: false , parts: [525,543,503,483,504,472,457,442]},
	{id: 173, cityId: 5,  date: 1589673600, distance: 11140, time:5726, slides: 0 , isCompetition: false , parts: [512,536,521,499,503,507,526,527,526,505,497]},
	{id: 174, cityId: 5,  date: 1589846400, distance: 9350,  time:4707, slides: 0 , isCompetition: false , parts: [521,549,513,491,505,513,492,493,465]},
	{id: 175, cityId: 5,  date: 1590019200, distance: 9480,  time:4775, slides: 0 , isCompetition: false , parts: [513,548,511,501,502,507,502,489,478]},
	{id: 176, cityId: 5,  date: 1590192000, distance: 14700, time:7443, slides: 0 , isCompetition: false , parts: [511,567,519,503,507,508,494,502,519,505,508,519,498,464]},
	{id: 177, cityId: 5,  date: 1590451200, distance: 9380,  time:4435, slides: 0 , isCompetition: false , parts: [486,540,477,464,476,476,461,454,442]},
	{id: 178, cityId: 5,  date: 1590624000, distance: 6210,  time:3130, slides: 0 , isCompetition: false , parts: [510,548,533,499,502,463]},
	{id: 179, cityId: 5,  date: 1590796800, distance: 9110,  time:4225, slides: 0 , isCompetition: false , parts: [465,497,452,434,470,492,477,479,412]},
	{id: 180, cityId: 5,  date: 1590969600, distance: 11020, time:5133, slides: 0 , isCompetition: false , parts: [467,486,452,442,484,480,457,511,482,453,413]},
	{id: 181, cityId: 5,  date: 1591142400, distance: 11080, time:5304, slides: 0 , isCompetition: false , parts: [481,505,464,449,491,458,486,522,482,484,442]},
	{id: 182, cityId: 5,  date: 1591401600, distance: 8010,  time:3944, slides: 0 , isCompetition: false , parts: [489,509,472,460,507,513,505,446]},
	{id: 183, cityId: 5,  date: 1591747200, distance: 8080,  time:3629, slides: 0 , isCompetition: false , parts: [473,482,469,422,458,458,473,419]},
	{id: 184, cityId: 5,  date: 1591920000, distance: 8110,  time:3844, slides: 0 , isCompetition: false , parts: [494,525,473,449,474,481,472,430]},
	{id: 185, cityId: 1,  date: 1592611200, distance: 10160, time:4725, slides: 3 , isCompetition: "https://onlinerunning.runukraine.org/race/new-york-run/" ,  parts:[385,446,475,440,476,489,475,517,465,487]},
	{id: 186, cityId: 1,  date: 1593216000, distance: 5150,  time:2037, slides: 0 , isCompetition: false , parts: [383,397,410,383,397]},
	{id: 187, cityId: 1,  date: 1593820800, distance: 10080, time:4717, slides: 3 , isCompetition: "https://onlinerunning.runukraine.org/race/berlin-run/" ,  parts:[451,450,446,443,453,462,471,483,501,514]},
	{id: 188, cityId: 6,  date: 1595030400, distance: 10080, time:4619, slides: 1 , isCompetition: "https://onlinerunning.runukraine.org/en/race/london-run/" ,  parts:[425,441,436,448,449,455,468,459,492,502]},
	{id: 189, cityId: 5,  date: 1595376000, distance: 6060,  time:2853, slides: 0 , isCompetition: false , parts: [481,497,468,473,474,435]},
	{id: 190, cityId: 5,  date: 1595635200, distance: 6070,  time:2700, slides: 0 , isCompetition: false , parts: [462,475,438,438,440,415]}
  ]
};

export default joggingsListItems;
