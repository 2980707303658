import React, { Component } from 'react';
import './Strava.scss';

class Strava extends Component{
    render(){
        return( 
            <div className = "Strava">
                Strava
            </div>
        );
    }
}
export default Strava;